<template>
	<div class="price-background">
		<div class="price-toggle">
			<div class="monthly" :class="{active: !isYear}">
				월간
			</div> 
			<BaseSwitch v-model="isYear" />
			<div class="yearly" :class="{active: isYear}">
				연간
				<span class="sale">최대 16% 할인</span>
				<div class="price-tooltip">
					<div class="arrow"></div>
					<div class="inner">연간 결제가 더 저렴해요!</div>
				</div>
			</div>
			<div v-if="$root.isPc" class="estimate-wrap">
				<div class="estimate-tooltip">견적서 필요하세요?</div>
				<div class="estimate-btn" @click="showBtn">견적서 발급 바로가기</div>
			</div>
		</div>
		<div class="price-tab">
			<div class="tab" :class="{active: tab == 'free'}" @click="tab = 'free'">FREE</div>
			<!-- <div class="tab" :class="{active: tab == 'basic'}" @click="tab = 'basic'">BASIC</div> -->
			<div class="tab" :class="{active: tab == 'standard'}" @click="tab = 'standard'">STANDARD</div>
			<div class="tab" :class="{active: tab == 'education'}" @click="tab = 'education'">대학 무제한</div>
		</div>
		<div class="price-layout pc">
			<img src="./image/image-price-free.png" />
			<!-- <img class="not-allow" :src="require(`./image/image-price-basic-${isYear ? 'yearly' : 'monthly'}.png`)"  v-b-tooltip.html.bottom="'베이직 요금은<br>곧 출시될 예정입니다.'" /> -->
			<img :src="require(`./image/image-price-standard-${isYear ? 'yearly' : 'monthly'}.png`)" />
			<img v-if="!isYear" class="not-allow"  v-b-tooltip.bottom.html="'연간결제만 가능합니다.'" src="./image/image-price-education-disabled.png"  />
			<img v-else src="./image/image-price-education.png"  />
		</div>
		<div class="price-layout mo">
			<img v-if="tab == 'free'" src="./image/m-image-price-free.png" />
			<!-- <div class="price-item"  v-else-if="tab == 'basic'">
				<img class="not-allow" :src="require(`./image/m-image-price-basic-${isYear ? 'yearly' : 'monthly'}.png`)" />
				<div class="not-allow-tooltip">
					<div class="arrow"></div>
					<div class="inner">
						곧 출시될 예정입니다.
					</div>
				</div>
			</div> -->
			<img v-else-if="tab == 'standard'" :src="require(`./image/m-image-price-standard-${isYear ? 'yearly' : 'monthly'}.png`)" />
			<div class="price-item" v-else-if="!isYear && tab=='education'">
				<img  class="not-allow" src="./image/m-image-price-education-disabled.png"  />
				<div class="not-allow-tooltip">
					<div class="arrow"></div>
					<div class="inner">
						연간결제만 가능합니다.
					</div>
				</div>
			</div>
			<img v-else-if="isYear  && tab=='education'" src="./image/m-image-price-education.png"  />
		</div>
		<modal-estimate-form />
	</div>
</template>

<script>
import ModalEstimateForm from '@/components/modals/ModalEstimateForm.vue'
import BaseSwitch from '../../ui/page/BaseSwitch.vue';
export default {
	name: 'PriceBox',
	components: {
		BaseSwitch,
		ModalEstimateForm,
	},
	data(){
		return {
			isYear: true,
			tab: 'free',
		}
	},
	methods: {
		showBtn() {
			this.$bvModal.show('estimate-form')
		},
	},
}
</script>

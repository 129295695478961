<template>
	<div id="Survey">
		<div class="intro">
			<div class="wrap">
				<img :src="intro01">
				<h1 v-if="isMobile">가치있는 연구에<br>집중할 수 있도록<br><img :src="require('@/assets/svg/survey/logo_survey.svg')">전수조사를<br>진행하세요.</h1>
				<h1 v-else>가치있는 연구에 집중할 수 있도록<br><img :src="require('@/assets/svg/survey/logo_survey.svg')">전수조사를 진행하세요.</h1>
				<div>
					<h6 v-if="isMobile">우리 연구실의 화학물질 보유현황을<br>하나의 데이터베이스로 구축할 수 있습니다.</h6>
					<h6 v-else>화학물질 보유현황을 하나의 데이터베이스로 구축할 수 있습니다.</h6>
					<div>
						<p>by</p>
						<span>전문 조사원</span>
						<span>랩매니저 보유 화학물질 데이터</span>
						<span>시약 라벨 AI 분석 기술</span>
					</div>
				</div>
				<button @click="goRequest">문의하기</button>
			</div>
		</div>
		<div class="advantages">
			<div class="wrap" v-if="isMobile">
				<Swiper :list="advantagesList" :options="advantagesOption">
					<template slot="index">
						<h1 style="text-align: center">화학물질 현황 파악으로<br>연구실의 데이터베이스를<br>구축하세요.</h1>
					</template>
				</Swiper>
			</div>
			<div v-else class="image_wrap">
				<img :src="image01">
			</div>
		</div>
		<div class="image_wrap">
			<img :src="image02">
		</div>
		<div class="image_wrap">
			<img :src="image03">
		</div>
		<div class="image_wrap">
			<img :src="image04">
		</div>
		<div class="image_wrap">
			<img :src="image05">
		</div>
		<div class="example_wrap">
			<div class="wrap">
				<Swiper v-if="isMobile" :list="
				tab == 'enterprise'
				? enterprise
				: tab == 'government'
				? government
				: education"
				:options="exampleOption">
					<template slot="index">
						<h1 style="text-align: center">이미 많은 곳에서<br><img style="width: 200px; margin-right: 10px; margin-bottom: 7px" :src="require('@/assets/svg/survey/logo_survey.svg')">전수조사<br>서비스의 효율을<br>경험했습니다!</h1>
						<div class="example_tab">
							<div class="tab" :class="{ active: tab == 'government' }" @click="tab = 'government'">국가 및 정출연</div>
							<div class="tab" :class="{ active: tab == 'enterprise' }" @click="tab = 'enterprise'">기업</div>
							<div class="tab" :class="{ active: tab == 'education' }" @click="tab = 'education'">대학교</div>
						</div>
					</template>
				</Swiper>
				<Swiper v-else :list="exampleList" :options="exampleOption">
					<template slot="index">
						<h2 style="text-align: center; font-weight: 700;">이미 많은곳에서 랩매니저 전수조사<br>서비스의 효율을 경험했습니다!</h2>
					</template>
				</Swiper>
			</div>
		</div>
		<div class="image_wrap">
			<img :src="image06">
		</div>
		<div>
			<div v-if="isMobile" class="wrap" style="background: #fef9ec">
				<div>
					<Swiper :list="priceList" :options="priceOption">
						<template slot="index">
							<h1>
								<img style="width: 200px; margin-right: 10px; margin-bottom: 7px" :src="require('@/assets/svg/survey/logo_survey.svg')">
								전수조사<br>
								서비스 비용을 알려드립니다.
							</h1>
						</template>
					</Swiper>
				</div>
				<img style="width: 100%" :src="image07">
			</div>
			<div v-else class="image_wrap">
				<img :src="image07">
			</div>
		</div>
		<!-- <div class="image_wrap">
			<img :src="image08">
		</div> -->
		<div class="image_wrap">
			<img :src="image09">
		</div>
		<div class="more">
			<div class="wrap">
				<div>
					<h1>지금 바로 문의하세요!</h1>
					<h6>전수조사 담당자가 연락 드리겠습니다.</h6>
					<div class="button-wrap">
						<button @click="goRequest" class="btn-down">문의하기</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import IntroLayout from '@/ui/page/IntroLayout'
// import SlideLayout from '@/ui/page/SlideLayout'
// import MobileCarousel from '@/ui/page/MobileCarousel'
import Swiper from "@/components/Swiper.vue";

export default {
	name: 'Survey',
	components: {
		// MobileCarousel,
		// SlideLayout,
		// IntroLayout,
		Swiper,
	},
	metaInfo: {
		title: '전수조사_랩매니저 PRO',
		meta: [
			{vmid:'keywords', name:'keywords', content: 'labmanager, lab manager, 랩매니저, 랩 매니저, 랩매니저 프로, 랩매니저 PRO, smartjack, 스마트잭, 연구실 관리, 시약 관리, 연구실 시약관리, MSDS, 머크 할인, 전수조사, 재고조사, 재고수량조사' },
			{vmid:'description', name:'description', content: '랩매니저 PRO 전수조사. 생각만 해도 머리아픈 우리 연구실 시약 재고관리, 랩매니저 전수조사로 깔끔하게 재고 데이터를 정리해보세요' },
			{vmid:'og:description', property:'description', content: '랩매니저 PRO 전수조사. 생각만 해도 머리아픈 우리 연구실 시약 재고관리, 랩매니저 전수조사로 깔끔하게 재고 데이터를 정리해보세요' },
		]
	},
	computed: {
		isMobile() {
			return window.matchMedia("(max-width: 767px)").matches ?? false
		},
		intro01() {
			return require('@/assets/svg/survey/intro_01.svg')
		},
		exampleList() {
			return [
				{
					images: [require("@/assets/image/survey/swiper/example_01.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_02.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_03.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_04.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_05.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_06.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_07.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_08.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_09.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_10.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_11.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_12.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_13.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_14.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_15.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_16.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/example_17.png")],
				},
			]
		},
		government() {
			return [
				{
					images: [require("@/assets/image/survey/swiper/m_government_01.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_government_02.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_government_03.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_government_04.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_government_05.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_government_06.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_government_07.png")],
				},
			]
		},
		enterprise() {
			return [
				{
					images: [require("@/assets/image/survey/swiper/m_enterprise_01.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_enterprise_02.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_enterprise_03.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_enterprise_04.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_enterprise_05.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_enterprise_06.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_enterprise_07.png")],
				},
			]
		},
		education() {
			return [
				{
					images: [require("@/assets/image/survey/swiper/m_education_01.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_education_02.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_education_03.png")],
				},
			]
		},
		exampleOption() {
			return this.isMobile ? {
				// loop: true,
				slidesPerView: 1,
				slidesPerGroup: 1,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				spaceBetween: -80,
			} : {
				loop: true,
				slidesPerView: 3,
				slidesPerGroup: 3,
				// centeredSlides: true,
				loopFillGroupWithBlank: true,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				isNavigation: true,
				spaceBetween: -50,
				// breakpoints: {
				// 	1024: {
				// 		slidesPerView: 4,
				// 		spaceBetween: 40
				// 	},
				// 	768: {
				// 		slidesPerView: 3,
				// 		spaceBetween: 30
				// 	},
				// 	640: {
				// 		slidesPerView: 2,
				// 		spaceBetween: 20
				// 	},
				// 	320: {
				// 		slidesPerView: 1,
				// 		spaceBetween: 10
				// 	}
				// }
			}
		},
		advantagesList() {
			return [
				{
					images: [require("@/assets/image/survey/swiper/m_advantages_01.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_advantages_02.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_advantages_03.png")],
				},
			]
		},
		advantagesOption() {
			return {
				loop: true,
				slidesPerView: 1,
				slidesPerGroup: 1,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				isNavigation: true,
				// spaceBetween: -80,
			}
		},
		priceList() {
			return [
				{
					images: [require("@/assets/image/survey/swiper/m_price_01.png")],
				},
				{
					images: [require("@/assets/image/survey/swiper/m_price_02.png")],
				},
			]
		},
		priceOption() {
			return {
				slidesPerView: 1,
				slidesPerGroup: 1,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				spaceBetween: -80,
			}
		},
		image01() {
			return require("@/assets/image/survey/survey_01.png")
		},
		image02() {
			return this.isMobile ? require('@/assets/image/survey/m_survey_02.png') : require("@/assets/image/survey/survey_02.png")
		},
		image03() {
			return this.isMobile ? require('@/assets/image/survey/m_survey_03.png') : require("@/assets/image/survey/survey_03.png")
		},
		image04() {
			return this.isMobile ? require('@/assets/image/survey/m_survey_04.png') : require("@/assets/image/survey/survey_04.png")
		},
		image05() {
			return this.isMobile ? require('@/assets/image/survey/m_survey_05.png') : require("@/assets/image/survey/survey_05.png")
		},
		image06() {
			return this.isMobile ? require('@/assets/image/survey/m_survey_06.png') : require("@/assets/image/survey/survey_06.png")
		},
		image07() {
			return this.isMobile ? require('@/assets/image/survey/m_survey_07.png') : require("@/assets/image/survey/survey_07.png")
		},
		image08() {
			return this.isMobile ? require('@/assets/image/survey/m_survey_08.png') : require("@/assets/image/survey/survey_08.png")
		},
		image09() {
			return this.isMobile ? require('@/assets/image/survey/m_survey_09.png') : require("@/assets/image/survey/survey_09.png")
		},
	},
	data() {
		return {
			tab: "government",
		}
	},
	methods: {
		goRequest() {
			this.$router.push({
				name: 'Request',
			})
		},
	},
}
</script>

<style lang="scss">
@import '@/assets/css/_survey.scss';
</style>

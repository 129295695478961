<template>
  <div id="Home" ref="home">
    <div class="intro">
      <div class="wrap">
        <Swiper
          v-if="isMobile"
          :list="introList"
          :options="introOptions"
          @actionSlide="actionSlide"
          @click="(e) => mainSlideClick(e)"
        >
        </Swiper>
        <Carousel
          v-else
          :options="introOptions"
          :list="introList"
          :perPage="1"
          :padding="0"
          @actionSlide="actionSlide"
          @click="(e) => mainSlideClick(e)"
        >
        </Carousel>
      </div>
    </div>
    <div class="refer">
      <div class="wrap">
        <Swiper
          v-if="isMobile"
          :list="
            tab == 'enterprise'
              ? enterprise
              : tab == 'government'
              ? government
              : education
          "
          :options="referOption"
        >
          <template slot="index">
            <h2 style="font-weight: 700">
              {{ tt("연구실 생활이 ") }}<br />{{ tt("더 편리해집니다!") }}
            </h2>
            <span style="margin-top: 20px; font-size: 24px">{{
              tt("10,000개 넘는 연구실이 이미 경험했습니다.")
            }}</span>
            <div class="refer-tab">
              <div
                class="tab"
                :class="{ active: tab == 'enterprise' }"
                @click="tab = 'enterprise'"
              >
                기업
              </div>
              <div
                class="tab"
                :class="{ active: tab == 'government' }"
                @click="tab = 'government'"
              >
                정부출연연구소
              </div>
              <div
                class="tab"
                :class="{ active: tab == 'education' }"
                @click="tab = 'education'"
              >
                대학교
              </div>
            </div>
          </template>
        </Swiper>
        <Carousel
          v-else
          :options="referOption"
          :list="
            tab == 'enterprise'
              ? enterprise
              : tab == 'government'
              ? government
              : education
          "
          :perPage="1"
        >
          <template slot="index">
            <h2 style="font-weight: 700">
              {{ tt("연구실 생활이 ") }}<br />{{ tt("더 편리해집니다!") }}
            </h2>
            <span style="margin-top: 20px; font-size: 24px">{{
              tt("10,000개 넘는 연구실이 이미 경험했습니다.")
            }}</span>
            <div class="refer-tab">
              <div
                class="tab"
                :class="{ active: tab == 'enterprise' }"
                @click="tab = 'enterprise'"
              >
                기업
              </div>
              <div
                class="tab"
                :class="{ active: tab == 'government' }"
                @click="tab = 'government'"
              >
                정부출연연구소
              </div>
              <div
                class="tab"
                :class="{ active: tab == 'education' }"
                @click="tab = 'education'"
              >
                대학교
              </div>
            </div>
          </template>
        </Carousel>
      </div>
    </div>
    <div class="review">
      <div class="wrap">
        <h2
          v-html="
            tt('랩매니저 PRO 도입 후<br>무엇이 더 좋아졌는지 물었습니다.')
          "
        ></h2>
        <h4>{{ tt("더 자세한 내용은 아래 버튼을 클릭해 확인해 보세요!") }}</h4>
        <button
          class="btn-show-more"
          @click="goInterview"
          style="margin-top: 20px"
        >
          {{ tt("모든 고객 사례 보기") }}
        </button>
        <Swiper v-if="isMobile" :list="reviews" :options="reviewOption">
        </Swiper>
        <div v-else>
          <vue-glide
            ref="slider"
            v-model="reviewSlide"
            type="carousel"
            :options="glideOptions"
          >
            <vue-glide-slide v-for="(slide, index) in reviews" :key="index">
              <img :src="slide.image" />
            </vue-glide-slide>
            <template slot="control" v-if="$root.isPc">
              <button class="btn-next" data-glide-dir="<">prev</button>
              <button class="btn-next" data-glide-dir=">">next</button>
            </template>
          </vue-glide>
          <div class="navigator">
            <div
              class="point"
              v-for="(_, index) in reviews"
              :key="'nav_' + index"
              :class="{ active: index == reviewSlide }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="data">
      <div class="wrap">
        <h2
          v-html="tt('랩매니저 PRO,<br>기술력의 원천은<br> 데이터에 있습니다.')"
        ></h2>
        <h4
          v-html="
            tt('국내 최대 시약 정보 데이터베이스를<br> 보유하고 있습니다!')
          "
        ></h4>
        <div class="row">
          <div
            class="col-6 col-sm-4"
            v-for="(item, index) in dataList"
            :key="`data_${index}`"
          >
            <h3>
              {{ tt(item.value) }}<span class="unit">{{ item.unit }}</span>
            </h3>
            <p>{{ tt(item.title) }}</p>
          </div>
        </div>
        <h4
          v-html="
            tt('* 지속적인 신규 데이터 수집 및<br> 기존 데이터 업데이트 진행')
          "
        ></h4>
      </div>
    </div>
    <div class="image_wrap">
      <img style="width: 100%" :src="whyImage" />
    </div>
    <div class="image_wrap">
      <img style="width: 100%" :src="patentImage" />
    </div>
    <div class="field">
      <div class="wrap">
        <Swiper v-if="isMobile" :list="useFieldsList" :options="fieldOption">
          <template slot="index">
            <h2
              style="text-align: center; font-weight: 700"
              v-html="tt('랩매니저 PRO,<br>어떤 연구실에 적합할까요?')"
            ></h2>
            <span class="text_wrap">{{
              tt("시약 등 연구물품을 사용하는 모든 연구실에 적합합니다.")
            }}</span>
          </template>
        </Swiper>
        <Carousel
          v-else
          :options="referOption"
          :list="useFieldsList"
          :perPage="useFieldsPerPage"
        >
          <template slot="index">
            <h2
              style="text-align: center; font-weight: 700"
              v-html="tt('랩매니저 PRO,<br>어떤 연구실에 적합할까요?')"
            ></h2>
            <span style="margin: 20px 0 40px 0; font-size: 24px">{{
              tt("시약 등 연구물품을 사용하는 모든 연구실에 적합합니다.")
            }}</span>
          </template>
        </Carousel>
      </div>
    </div>
    <div class="more">
      <div class="wrap">
        <div>
          <h1>{{ tt("랩매니저 PRO에 대해 더 자세히 알아보세요!") }}</h1>
          <h6>
            {{
              tt(
                "직접 체험해 보시거나 소개서를 읽고 도입문의를 남겨주셔도 좋습니다."
              )
            }}
          </h6>
          <div class="button-wrap">
            <button
              id="tooltip-target-1"
              @click="serviceDownClick"
              class="btn-down"
            >
              {{ tt("서비스 소개서 다운로드") }}
            </button>
            <button
              id="tooltip-target-2"
              @click="$root.openApp"
              class="btn-black"
            >
              {{ tt("무료사용") }}
            </button>
            <b-tooltip
              disabled
              :show.sync="showTooltip"
              target="tooltip-target-1"
              placement="bottom"
              custom-class="tooltip-wrap"
              fallback-placement="clockwise"
            >
              {{ tt("랩매니저 PRO 서비스를") }}
              <br />
              {{ tt("디테일하게 확인해 보세요!") }}
            </b-tooltip>
            <b-tooltip
              disabled
              :show.sync="showTooltip"
              target="tooltip-target-2"
              placement="bottom"
              custom-class="tooltip-wrap"
              fallback-placement="clockwise"
            >
              {{ tt("무료로 먼저 시작해 보고") }}
              <br />
              {{ tt("결정하셔도 좋습니다!") }}
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>
    <modal-complete-request />
  </div>
</template>

<script>
import { Glide, GlideSlide } from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";
import ModalCompleteRequest from "@/components/modals/ModalCompleteRequest";
import Carousel from "@/components/Carousel2.vue";
import Swiper from "@/components/Swiper.vue";

export default {
  name: "Home",
  components: {
    ModalCompleteRequest,
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    Carousel,
    Swiper,
  },
  computed: {
    isMobile() {
      return window.matchMedia("(max-width: 767px)").matches ?? false;
    },
    showTooltip() {
      return !this.isMobile ?? false;
    },
    whyImage() {
      return this.isMobile
        ? require("@/assets/image/m_main_why.png")
        : require("@/assets/image/main_why.png");
    },
    patentImage() {
      return this.isMobile
        ? require("@/assets/image/m_main_patent.png")
        : require("@/assets/image/main_patent.png");
    },
    education() {
      return this.isMobile
        ? [
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_대학교01.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_대학교02.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_대학교03.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_대학교04.png"),
              ],
            },
          ]
        : [
            {
              images: [
                require("@/assets/image/reference/레퍼런스_대학교01.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/레퍼런스_대학교02.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/레퍼런스_대학교03.png"),
              ],
            },
          ];
    },
    enterprise() {
      return this.isMobile
        ? [
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_기업01.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_기업02.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_기업03.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_기업04.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_기업05.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_기업06.png"),
              ],
            },
          ]
        : [
            {
              images: [require("@/assets/image/reference/레퍼런스_기업01.png")],
            },
            {
              images: [require("@/assets/image/reference/레퍼런스_기업02.png")],
            },
            {
              images: [require("@/assets/image/reference/레퍼런스_기업03.png")],
            },
            {
              images: [require("@/assets/image/reference/레퍼런스_기업04.png")],
            },
          ];
    },
    government() {
      return this.isMobile
        ? [
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_정출연01.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/reference/m_레퍼런스_정출연02.png"),
              ],
            },
          ]
        : [
            {
              images: [require("@/assets/image/reference/레퍼런스_정출연.png")],
            },
          ];
    },
    useFieldsList() {
      return [
        {
          images: [require("@/assets/image/home/사용분야_01.png")],
        },
        {
          images: [require("@/assets/image/home/사용분야_02.png")],
        },
        {
          images: [require("@/assets/image/home/사용분야_03.png")],
        },
        {
          images: [require("@/assets/image/home/사용분야_04.png")],
        },
        {
          images: [require("@/assets/image/home/사용분야_05.png")],
        },
      ];
    },
    useFieldsPerPage() {
      return this.isMobile ? 1 : 4;
    },
    introList() {
      return this.isMobile
        ? [
            // {
            //   content:
            //     "<h1><b>역대급 할인이 쏟아진다!</b></h1><div class='text_wrap'><div><div class='image-merck'></div><h1>등</h1></div><div><h1><b>최대 20% 상시할인</b></h1><br></div></div><h6>연구실 재료비 부담, 랩매니저 PRO로 해결하세요.<br />머크 등 11개 인기 브랜드를 선택해<br />상시 할인혜택을 제공합니다.</h6><button id='moreMerckInfo'>자세히 알아보기</button>",
            //   images: [require("@/assets/image/intro/m_intro_01_e.png")],
            // },
			// {
            //   content:
            //     "<h1 style='display: flex'><div class='image-logo'></div><b>전수조사</b></h1><div class='text_wrap'><div></div><div><h1><b>50% 할인 프로모션</b><br />진행 중!</b></h1></div></div><h6>망설였다면 지금이 기회입니다.<br>2023년 말까지 진행되는<br>전국 30개 기관 한정 프로모션!</h6><button id='moreMerckInfo'>자세히 알아보기</button>",
            //   images: [require("@/assets/image/intro/m_intro_02_e.png")],
            // },
            {
              images: [
                require("@/assets/image/intro/m_intro_02_left.png"),
                require("@/assets/image/intro/m_intro_02_right.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/intro/m_intro_03_left.png"),
                require("@/assets/image/intro/m_intro_03_right.png"),
              ],
            },
          ]
        : [
            // {
            //   content:
			//   "<h1><b>역대급 할인이 쏟아진다!</b></h1><div class='text_wrap'><div><div class='image-merck'></div></div><div><h1><b>등 최대 20% 상시할인</b></h1><br></div></div><h6>연구실 재료비 부담, 랩매니저 PRO로 해결하세요.<br />머크 등 11개 인기 브랜드를 선택해 상시 할인혜택을 제공합니다.</h6><button id='moreMerckInfo'>자세히 알아보기</button>",
            //   images: [require("@/assets/image/intro/intro_01_e.png")],
            // },
			// {
            //   content:
            //     "<h1><div class='image-logo'></div><b>전수조사</b></h1><div class='text_wrap'><div></div><div><h1><b>50% 할인 프로모션 진행 중!</b></h1></div></div><h6>망설였다면 지금이 기회입니다.<br>2023년 말까지 진행되는 전국 30개 기관 한정 프로모션!</h6><button id='moreMerckInfo'>자세히 알아보기</button>",
            //   images: [require("@/assets/image/intro/intro_02_e.png")],
            // },
            {
              images: [
                require("@/assets/image/intro/intro_02_left.png"),
                require("@/assets/image/intro/intro_02_right.png"),
              ],
            },
            {
              images: [
                require("@/assets/image/intro/intro_03_left.png"),
                require("@/assets/image/intro/intro_03_right.png"),
              ],
            },
          ];
    },
    reviews() {
      return this.isMobile
        ? [
            {
              images: [require("@/assets/image/review/m_리뷰_01.png")],
            },
            {
              images: [require("@/assets/image/review/m_리뷰_02.png")],
            },
            {
              images: [require("@/assets/image/review/m_리뷰_03.png")],
            },
            {
              images: [require("@/assets/image/review/m_리뷰_04.png")],
            },
            {
              images: [require("@/assets/image/review/m_리뷰_05.png")],
            },
            {
              images: [require("@/assets/image/review/m_리뷰_06.png")],
            },
          ]
        : [
            {
              id: 1,
              image: require("@/assets/image/review/리뷰_01.png"),
            },
            {
              id: 2,
              image: require("@/assets/image/review/리뷰_02.png"),
            },
            {
              id: 3,
              image: require("@/assets/image/review/리뷰_03.png"),
            },
            {
              id: 4,
              image: require("@/assets/image/review/리뷰_04.png"),
            },
            {
              id: 5,
              image: require("@/assets/image/review/리뷰_05.png"),
            },
            {
              id: 6,
              image: require("@/assets/image/review/리뷰_06.png"),
            },
          ];
    },
    introOptions() {
      return this.isMobile
        ? {
            slidesPerGroup: 1,
            loop: true,
            autoplay: {
              delay: 3000,
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          }
        : {
            autoplay: true,
            autoplayTimeout: 3000,
            loop: true,
            navigationEnabled: true,
            navigationPrevLabel: "",
            navigationNextLabel: "",
          };
    },
    referOption() {
      return this.isMobile
        ? {
            loop: true,
            slidesPerView: 1.2,
            slidesPerGroup: 1,
            centeredSlides: true,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          }
        : {
            loop: true,
            navigationEnabled: true,
            navigationPrevLabel: "",
            navigationNextLabel: "",
          };
    },
    reviewOption() {
      return {
        loop: true,
        slidesPerView: 1.3,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      };
    },
    fieldOption() {
      return {
        loop: true,
        slidesPerView: 1.3,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      };
    },
  },
  data() {
    return {
      tab: "enterprise",
      introTab: 0,
      dataList: [
        { title: "화학물질 데이터", value: "110,000,000", unit: "종" },
        { title: "시약 데이터", value: "3,500,000", unit: "개" },
        { title: "제조사 MSDS", value: "700,000", unit: "종" },
        { title: "MSDS 분석", value: "404,000", unit: "종" },
        { title: "법령", value: "41,000", unit: "종" },
        { title: "물성 데이터", value: "23,000", unit: "종" },
      ],
      email: "",
      reviewSlide: 0,
      glideOptions: {
        focusAt: "center",
        gap: this.$root.isPc ? 60 : 20,
        peek: -245,
      },
    };
  },
  methods: {
    mainSlideClick(e) {
      const slide = this.isMobile
        ? document.querySelectorAll(".intro .swiper-slide")
        : document.querySelectorAll(".intro .VueCarousel-slide");
      const target = this.isMobile
        ? e.target.closest(".swiper-slide")
        : e.target.closest(".VueCarousel-slide");
      const arr = Array.from(slide);
      const index = arr.findIndex((it) => it === target);
      const bool = this.isMobile ? index != 1 : index != 0;
      if (e.target.id == "moreMerckInfo") {
		if(this.isMobile && index == 2 || !this.isMobile && index == 1) {
			window.open(`https://www.lab-manager.com/survey`);
		} else {
			window.open(`https://www.lab-manager.com/promotion/m10`);
		}
        
      } else if (bool) {
        window.open(`https://www.lab-manager.com/promotion/1`);
      }
    },
    actionSlide(index) {
      this.introTab = index;
    },
    goRequest() {
      window.ga("send", "event", "request", "request");
      this.$router.push({
        name: "Request",
      });
    },
    validation() {
      let isValid = true;

      let emailRule =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      if (this.email === "") {
        isValid = false;
        return alert("이메일 주소를 입력해주세요.");
      } else if (!emailRule.test(this.email)) {
        isValid = false;
        return alert("이메일이 형식에 맞지 않습니다. 다시 한 번 확인해주세요.");
      }

      return isValid;
    },
    async requestGuide() {
      if (!this.validation()) {
        return false;
      }

      try {
        await this.$service.client.submitRequest({
          email: this.email,
          type: "landing-phone",
        });
        window.marketingEvent("conversion");
        this.$bvModal.show("modal-complete-request");
        this.email = "";
      } catch (e) {
        console.log(e);
        alert(
          "오류로 인해 문의가 접수되지 않았습니다.\n다시 한 번 시도해주세요"
        );
      }
    },
    goAround() {
      this.$router.push({
        name: "Around",
        params: {
          key: "pro_gateway",
          value: "landing",
        },
      });
      //marketing: 무료로 시작하기 -> pro 로그인 되어있지 않았을 경우, 회원가입 유도 문구 노출 2022.11.21
      window.ga("send", "event", "demo_start", "demo_start");
      if (this.$root.isPc) {
        window.open("https://pro.lab-manager.com");
      } else {
        window.open("https://lmpro.page.link/app");
      }
    },
    goInterview() {
      window.open("https://www.lab-manager.com/blog/interview");
      // this.$router.push({
      // 	name: "Blog",
      // 	params: {
      // 		category: 'interview'
      // 	}
      // })
    },
    serviceDownClick() {
      window.open(
        "https://drive.google.com/file/d/1i3xLic3qC-Ovop2W2VT0dkiFf-dpQNBJ/view?usp=sharing"
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/_home.scss";
.tooltip-wrap {
  top: 10px !important;
  .arrow::before {
    border-top-color: #fff3d6 !important;
    border-bottom-color: #fff3d6 !important;
  }
  .tooltip-inner {
    color: #000;
    background-color: #fff3d6 !important;
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: 500;
  }
}
.glide {
  padding: 60px 0 40px;
  .glide__track {
    padding-top: 10px;
    .glide__slides {
      height: 330px;
      padding: 25px 0 0;
    }
    .glide__slide {
      text-align: left;
      height: 300px;
      // width: 650px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  button {
    width: 43px;
    height: 80px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(~@/assets/svg/icon-slide-arrow.svg);
    color: transparent;
    &:first-child {
      left: -120px;
      transform: rotate(180deg);
    }
    &:last-child {
      right: -120px;
      transform: rotate(0deg);
    }
  }
}

@media (max-width: 767px) {
  .glide {
    padding: 30px 0;
    .glide__track {
      .glide__slides {
        height: 300px;
      }
      .glide__slide {
        // min-width: 60vw;
        height: 277px;
        // img {
        // 	width: auto;
        // 	height: auto;
        // 	transform:translateZ(0);
        // }
        &.glide__slide--active {
          // height: 220px;
        }
        p {
          padding-top: 40px;
          background-size: 20px;
          font-size: 1rem;
          min-height: 80%;
          br {
            display: none;
          }
        }
        span {
          font-size: 0.875rem;
        }
      }
    }
  }
}
</style>

<template>
	<div id="app">
		<Header v-if="isHeaderShow" />
		<div id="body">
			<router-view />
			<floating-btns v-if="isHeaderShow || showFloating"></floating-btns>
		</div>
		<Footer v-if="isHeaderShow"/>
	</div>
</template>

<script>
import mixinSession from '@/utils/session'
import Header from '@/components/Header'
import FloatingBtns from '@/components/page/FloatingBtns.vue'
import Footer from '@/components/Footer'
import logger from '@/logger/logger'
import axios from 'axios'

export default {
	created() {
		if(this.isHeaderShow || this.showFloating) {
			this.initChannelTalk();
		}
	},
	mounted() {
		// this.sessionInit()
	},
	components: {
		Header,
		Footer,
		FloatingBtns,
	},
	metaInfo: {
		title: '연구실 시약 관리는 랩매니저 프로',
		meta: [
			{vmid:'keywords', name:'keywords', content: 'labmanager, lab manager, 랩매니저, 랩 매니저, 랩매니저 프로, 랩매니저 PRO, smartjack, 스마트잭, 연구실 관리, 시약 관리, 연구실 시약관리, MSDS, 머크 할인' },
			{vmid:'description', name:'description', content: '연구실 시약 관리가 걱정이라면, 랩매니저 프로 | 제약, 바이오, 식품업계가 선택한 시약 관리 솔루션' },
			{vmid:'og:description', property:'description', content: '연구실 시약 관리가 걱정이라면, 랩매니저 프로 | 제약, 바이오, 식품업계가 선택한 시약 관리 솔루션' },
		]
	},
	mixins: [mixinSession],
	computed: {
		isHeaderShow() {
			if (this.noHeaderPages.indexOf(this.$route.path) >= 0) {
				return false
			} else {
				return true
			}
		},
	},
	data() {
		return {
			showFloating: ['/promotion/m10'],
			noHeaderPages: ['/event', '/gatheringInfo', '/gatheringForm', '/event/discount', '/event/2023/01', '/event/2023/01/request', '/event/2023/01/complete', '/promotion/m10', '/promotion/1', '/promotion/2', '/estimate'],
		}
	},
	methods: {
		processExit: (e, current_route, session) => {
			e.preventDefault()
			logger.routeReport('EXIT', current_route, session)
		},
		sessionInit: function() {
			axios
				.get('https://lab-manager.com/remote')
				.then(res => {
					this.$store.commit('initProfile', res.data)
					return res.data
				})
				.then(session => {
					session
					const s = this.$store.getters.getSession
					window.addEventListener('beforeunload', e => this.processExit(e, this.$route.name, s))
					logger.routeReport('HOME', 'EXTERNAL', s)
				})
		},
		processRoute: async function(to, from) {
			await logger.routeReport(to.name, from.name, this.$store.getters.getSession)
			await this.$store.commit('updateLastElapsedMs', new Date().getTime())
			window.scrollTo(0, 0)
		},
	},
	watch: {
		$route(to, from) {
			this.processRoute(to, from)
		},
	},
}
</script>

<style lang="scss" scoped>
// @media(max-width:767px) {
//  #body {
//    margin-top : 56px;
//  }
// }
</style>
